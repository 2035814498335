import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`杉浦篤《無題》`}</h1>
    <p>{`角が取れて丸くなったり、折れていたり、半分くらいの大きさになっていたりする写真の数々。表面にはたくさんの傷がついて、白く剥げてしまっている箇所もあります。杉浦篤さんは、家族などが撮影した写真を大事に持ち歩いて、生活の中のいろいろな時に鑑賞します。ベッドの上や、クローゼット、箱の中など、なんども移動させ、また箱の中では下から上へかき混ぜるので、こんな風になっていくのだそうです。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "871px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "64.375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHSW6BYVE//xAAZEAEBAAMBAAAAAAAAAAAAAAACAQADEhD/2gAIAQEAAQUCS4J29XLIodZPn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAEBAAY/AqTIziz/xAAYEAEBAQEBAAAAAAAAAAAAAAABABExcf/aAAgBAQABPyE9Hlkg7Ni3ST3Jlv/aAAwDAQACAAMAAAAQwM//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EBKyP//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxBFdn//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhQVFx/9oACAEBAAE/ECAWoPuUZReB4e8C6NL9jmN8S1eZLv/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/dccaf8c6f8a4758ceafaab669689b1e2/ec4f2/atsushi-sugiura.webp 320w", "/static/dccaf8c6f8a4758ceafaab669689b1e2/74ed4/atsushi-sugiura.webp 640w", "/static/dccaf8c6f8a4758ceafaab669689b1e2/28c01/atsushi-sugiura.webp 871w"],
          "sizes": "(max-width: 871px) 100vw, 871px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/dccaf8c6f8a4758ceafaab669689b1e2/9328e/atsushi-sugiura.jpg 320w", "/static/dccaf8c6f8a4758ceafaab669689b1e2/f24a5/atsushi-sugiura.jpg 640w", "/static/dccaf8c6f8a4758ceafaab669689b1e2/494e2/atsushi-sugiura.jpg 871w"],
          "sizes": "(max-width: 871px) 100vw, 871px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/dccaf8c6f8a4758ceafaab669689b1e2/494e2/atsushi-sugiura.jpg",
          "alt": "atsushi sugiura",
          "title": "atsushi sugiura",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <Caption before={`作品`} medium={`写真、1997年～`} mdxType="Caption">
  《無題》
    </Caption>
    <BpHr type={`dot`} color={`invert`} mdxType="BpHr" />
    <LinkButton color={`invert`} to={`/works/dj-sniff/`} mdxType="LinkButton">応答する人: dj sniff</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      